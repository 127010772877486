
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { format, isValid, parse, parseISO } from 'date-fns';

const Order = () => {
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    const [summary, setSummary] = useState(null);

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0"> </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="  p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
            </span>
        </div>
    );

    const fetchProfileData = async () => {
        setData([])
        const url = `${process.env.REACT_APP_API_URL}/orders`;
        let data = await axios.post(url, { IDPage: page + 1 },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.Orders);
        setPagesNum(data?.data?.Response?.Pages)
        setSummary(data?.data?.Response) 
    }
    useEffect(() => {
        fetchProfileData();
    }, [isLang, page])




    const [editingRow, setEditingRow] = useState(null);



    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        await axios.post(`${process.env.REACT_APP_API_URL}/orders/status`, {
            IDOrder: rowData?.IDOrder,
            OrderStatus: e.value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchProfileData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };

    const getStatusOptions = (status) => {
        switch (status) {
            case 'PENDING':
                return [
                    { label: t('ACCEPTED'), value: 'ACCEPTED' },
                    { label: t('REJECTED'), value: 'REJECTED' }
                ];
            case 'ACCEPTED':
                return [
                    { label: t('OUT_FOR_DELIVERY'), value: 'OUT_FOR_DELIVERY' },
                    /*          { label: t('ARRIVED_AT_STATION'), value: 'ARRIVED_AT_STATION' },
                             { label: t('DELIVERED'), value: 'DELIVERED' } */
                ];
            case 'OUT_FOR_DELIVERY':
                return [
                    { label: t('ARRIVED_AT_STATION'), value: 'ARRIVED_AT_STATION' },
                    // { label: t('DELIVERED'), value: 'DELIVERED' }
                ];
            case 'ARRIVED_AT_STATION':
                return [
                    { label: t('DELIVERED'), value: 'DELIVERED' }
                ];
            case 'DELIVERED':
                return [
                    { label: t('ARRIVED_AT_STATION'), value: 'ARRIVED_AT_STATION' },
                    { label: t('DELIVERED'), value: 'DELIVERED' }
                ];
            default:
                return [];
        }
    };

    const statusBodyTemplate = (rowData) => {
        const options = getStatusOptions(rowData?.OrderStatus);
        const editingStatus = rowData?.OrderStatus === 'ACCEPTED' ? t('ACCEPTED') : rowData?.OrderStatus === 'RETURNED' ? t('RETURNED') : rowData?.OrderStatus === 'CANCELLED' ? t('CANCELLED') : rowData?.OrderStatus === 'OUT_FOR_DELIVERY' ? t('OUT_FOR_DELIVERY') : rowData?.OrderStatus === 'REJECTED' ? t('REJECTED') : rowData?.OrderStatus === 'ARRIVED_AT_STATION' ? t('ARRIVED_AT_STATION') : rowData?.OrderStatus === 'DELIVERED' ? t('DELIVERED') : t('pending');

        return editingRow === rowData?.IDOrder ? (
            <Dropdown
                value={rowData?.OrderStatus}
                options={options}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={editingStatus}
                className="p-inputtext-sm dropdown_filter "
                style={{ fontSize: '12px', lineHeight: '20px' }}
            />
        ) : (
            <Tag
                rounded
                value={editingStatus}
                severity={getSeverity(rowData.OrderStatus)}

            />
        );
    };



    const getSeverity = (status) => {
        switch (status) {
            case 'OUT_FOR_DELIVERY':
                return 'warning';
            case 'REJECTED':
                return 'danger';
            case 'DELIVERED':
                return 'success';
            case 'ARRIVED_AT_STATION':
                return 'Info';
            case 'PENDING':
                return 'warning';
            case 'ACCEPTED':
                return 'success';
            case 'RETURNED':
                return 'danger';


        }
    };





    const dateTime = (rowData) => {
        const orderDate = rowData?.OrderDate;

        if (!orderDate) {
            return <span>Invalid date</span>;
        }

        const orderDateTime = parseISO(orderDate);

        if (!isValid(orderDateTime)) {
            return <span>Invalid date</span>;
        }

        const formattedDateTime = format(orderDateTime, 'dd-MM-yyyy HH:mm');
        return <span>{formattedDateTime}</span>;
    };


    // handel btn action in tabel 

    // handel btn action in tabel 
    const menu = useRef(null);
    const [idClients, setIdClients] = useState(null);

    const items = [
        {
            label: <Link to={`/clients/details/${idClients?.IDClient}`}>{t('client_details')}</Link>,
            icon: 'pi pi-user',
        },
        {
            label: <Link to={`/orders/details/${idClients?.IDOrder}`}>{t('orderDetails')}</Link>,
            icon: 'pi pi-shopping-cart',
        },

    ];
    const actionBodyTemplate = (rowData) => {

        return (
            <div className="flex   justify-content-center align-content-center">
                <Link to={`/orders/details/${rowData?.IDOrder}`}>
                    <Button icon="pi pi-eye" severity="danger" rounded outlined text raised className="mr-2" />
                </Link>
            </div>
        );
    };
    return (
        <div className='mb-8 '>
            {/* <SummaryClient data={summary} /> */}
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">

                <DataTable
                    className='custom-datatable'
                    scrollable
                    header={header}
                    scrollHeight='550px'
                    value={Data}
                    dataKey='id'
                    showGridlines
                    globalFilter={globalFilter}
                    selectionMode='single'
                >
                    <Column field="OrderBatchNumber" header={t('ledger_Batchnumber')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="ClientName" header={t('name')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="ClientPhone" header={t('phone')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="OrderAmount" header={t('amount')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="OrderOfferAmount" header={t('discount')} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                    {/* <Column field="PaymentMethod" header={t('PaymentMethod')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    {/* <Column field="OrderShippingAmount" header={t('ShippingAmount')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    <Column field="OrderStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                    <Column field="OrderDate" header={isLang === "en" ? 'Order Date' : 'تاريخ الطلب'} body={dateTime} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                </DataTable>
                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>
        </div>
    );
};

export default Order
