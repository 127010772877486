import axios from 'axios';
import img from 'constants/Img';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import PreviewsImage from '../Brand/PreviewsImage';


const AddProducts = () => {
  let { categoriesAjex, fetchCategoriesAjex, fetchSubCategoriesAjex, subCategoriesAjex, brandsAjex, fetchBrandsAjex } = useContext(FetchApi)

  let { isLang, selectedUpload } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/brands/products/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()

  const BrandType = [
    { name: t('CLOTHES'), value: 'CLOTHES' },
    { name: t('COSMETICS'), value: 'COSMETICS' },
    // { name: 'Private', value: 'PRIVATE' },
  ];
  const items = [
    { label: <Link to={'/products'}>{t('products')}   </Link> },
    { label: <Link to={'/products/add'} className='p-2 border-round'>  {t('products_add')}  </Link> }
  ];

  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageSelect = (event) => {
    setSelectedImage(event.target.files[0]);
  };
  const [selectedIcon, setSelectedIcon] = useState(null);
  const handleIconSelect = (event) => {
    setSelectedIcon(event.target.files[0]);
  };

  const home = { icon: 'pi pi-home', url: '/' };

  const formik = useFormik({
    initialValues: {
      BrandProductTitleEn: '',
      BrandProductTitleAr: '',
      BrandProductDescEn: '',
      BrandProductDescAr: '',
      BrandProductOfferPrice: '',
      BrandProductPrice: '',
      BrandDescAr: '',
      CategoryType: 'COSMETICS',
      IDCategory: '',
      IDSubCategory: '',
      IDBrand: '',
      BrandProductLogo: selectedImage,
      BrandProductGallery: selectedUpload,
      BrandProductDealImage: selectedIcon,

    },
    onSubmit: async (values, { resetForm }) => {
      
      console.log(selectedUpload);
      try {
        setLoading(true);
        let { data } = await axios.post(url, {
          IDBrand: values?.IDBrand,
          IDSubCategory: values?.IDSubCategory,
          BrandProductTitleEn: values?.BrandProductTitleEn,
          BrandProductTitleAr: values?.BrandProductTitleAr,
          BrandProductDescEn: values?.BrandProductDescEn,
          BrandProductDescAr: values?.BrandProductDescAr,
          BrandProductOfferPrice: values?.BrandProductOfferPrice,
          BrandProductPrice: values?.BrandProductPrice,
          BrandProductLogo: values?.BrandProductLogo,
          BrandProductGallery: selectedUpload,
          BrandProductDealImage: selectedIcon,
        },
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/products')
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue('BrandProductLogo', selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    formik.setFieldValue('BrandProductDealImage', selectedIcon);
  }, [selectedIcon]);

  const defaultId = brandsAjex[0]?.IDBrand;
  const defaultIDCategory = categoriesAjex[0]?.IDCategory;
  const defaultIDSubCategory = subCategoriesAjex[0]?.IDSubCategory;
  
  useEffect(() => {
    if (formik.values.CategoryType) {
      fetchBrandsAjex(formik.values.CategoryType)
      formik.setFieldValue("IDBrand", brandsAjex[0]?.IDBrand)
      fetchCategoriesAjex(formik.values.CategoryType)
    }

    if (categoriesAjex) {
      fetchSubCategoriesAjex(formik.values.IDCategory)
      formik.setFieldValue("IDCategory", categoriesAjex[0]?.IDCategory)
      formik.setFieldValue("IDSubCategory", subCategoriesAjex[0]?.IDSubCategory)
    }
  }, [defaultId, defaultIDCategory]);

  
    
  useEffect(() => {
 

    if (categoriesAjex) { 
      formik.setFieldValue("IDSubCategory", subCategoriesAjex[0]?.IDSubCategory)
    }
  }, [ defaultIDSubCategory]);

  

  return (
    <div>
      <div className='mb-8'>
        <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

        <BreadCrumb model={items} home={home} />
        <PreviewsImage />

        <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
          <div className="grid  mt-5 flex justify-content-center a ">
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col flex justify-content-center align-items-center flex-column ">
              <div className=" shadow-4 mt-3   flex justify-content-center align-items-center m-auto " style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
                <div className="overflow-hidden flex justify-content-center align-items-center" style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
                  {formik.values.BrandProductLogo ? (
                    <Image
                      preview
                      loading="lazy"
                      src={URL.createObjectURL(formik.values.BrandProductLogo)}
                      alt={formik.values.BrandProductLogo.name}
                      width='300px'
                      height='250px'
                      className=' mx-auto '
                      style={{ objectFit: 'contain' }}
                    />
                  ) :
                    <>
                      <img
                        loading="lazy"
                        src={img.DefaultImage}
                        alt={'default-image'}
                        className=' rounded-circle mx-auto '
                        style={{ border: '6px solid #fff', width: '300px', maxHeight: '172' }}
                      />
                    </>
                  }

                </div>
                <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                  <input
                    type="file"
                    id="file-input"
                    accept="image/*"
                    onChange={handleImageSelect}
                    style={{ display: 'none' }}
                  />

                  <label htmlFor="file-input" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                    <i className='pi pi-image color-wight'></i>
                  </label>
                </div>
              </div>
              <label htmlFor="ClientPicture" className="font-bold text-xl block mb-2">{isLang === "en" ? "Main Image" : "الصورة الرئيسية"}</label>

            </div>
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col flex justify-content-center align-items-center flex-column ">
              <div className=" shadow-4 mt-3    flex justify-content-center m-auto " style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
                <div className="overflow-hidden flex justify-content-center align-items-center" style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
                  {formik.values.BrandProductDealImage ? (
                    <Image
                      preview
                      loading="lazy"
                      src={URL.createObjectURL(formik.values.BrandProductDealImage)}
                      alt={formik.values.BrandProductDealImage.name}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'contain', borderRadius: '10px' }}
                    />
                  ) :
                    <>
                      <img
                        loading="lazy"
                        src={img.DefaultImage}
                        alt={'default-image'}
                        className=' rounded-circle mx-auto '
                        style={{ border: '6px solid #fff', width: '300px', maxHeight: '172' }}
                      />
                    </>
                  }

                </div>
                <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                  <input
                    type="file"
                    id="file-input-icon"
                    accept="image/*"
                    onChange={handleIconSelect}
                    style={{ display: 'none' }}
                  />

                  <label htmlFor="file-input-icon" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                    <i className='pi pi-image color-wight'></i>
                  </label>
                </div>

              </div>
              <label htmlFor="ClientPicture" className="font-bold text-xl block mb-2">{isLang === "en" ? 'Deal Image' : 'صورة الصفقة'}</label>

            </div>
          </div>

          <div className="grid  mt-5  ">
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
              <label htmlFor="CategoryType" className="font-bold block mb-2">
                {t('type')}
              </label>
              <Dropdown
                id="CategoryType"
                name="CategoryType"
                value={formik.values.CategoryType}
                options={BrandType}
                optionLabel="name"
                onChange={(e) => {
                  formik.setFieldValue("CategoryType", e.value)
                  fetchCategoriesAjex(e.value)
                  fetchBrandsAjex(e.value)
                }}
                onBlur={formik.handleBlur}
                placeholder={isLang === "en" ? 'Select a type' : 'اختر النوع'}
                className="w-full p-inputtext-sm"
                required
              />
            </div>
            {
              formik.values.CategoryType &&
              <>
                <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
                  <label htmlFor="IDBrand" className="font-bold block mb-2"> {isLang === "en"?'Brand':'العلامة التجارية'}</label>
                  <Dropdown filter
                    options={brandsAjex?.map(item => ({
                      name: item.BrandName,
                      value: item.IDBrand,
                    }))}
                    id="IDBrand"
                    name="IDBrand"
                    optionLabel="name"
                    optionValue="value"
                    value={formik.values.IDBrand} // Add this line 
                    onChange={(e) => {
                      formik.setFieldValue("IDBrand", e.value)

                    }}

                    onBlur={formik.handleBlur}
                    placeholder={t('brand_placeholder')}
                    className="w-full p-inputtext-sm"
                  />
                </div>

                <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
                  <label htmlFor="IDCategory" className="font-bold block mb-2"> {t('categories_title')}</label>
                  <Dropdown filter
                    options={categoriesAjex?.map(item => ({
                      name: item.CategoryName,
                      value: item.IDCategory,
                    }))}
                    id="IDCategory"
                    name="IDCategory"
                    optionLabel="name"
                    optionValue="value"
                    value={formik.values.IDCategory} // Add this line 
                    onChange={(e) => {
                      formik.setFieldValue("IDCategory", e.value)
                      fetchSubCategoriesAjex(e.value)
                    }}

                    onBlur={formik.handleBlur}
                    placeholder={t('Category_placeholder')}
                    className="w-full p-inputtext-sm"
                  />
                </div>

              </>
            }
            {
              formik.values.IDCategory &&
              <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
                <label htmlFor="IDCategory" className="font-bold block mb-2"> {t('subcategories_title')}</label>
                <Dropdown filter
                  options={subCategoriesAjex?.map(item => ({
                    name: item.SubCategoryName,
                    value: item.IDSubCategory,
                  }))}
                  id="IDSubCategory"
                  name="IDSubCategory"
                  optionLabel="name"
                  optionValue="value"
                  value={formik.values.IDSubCategory} // Add this line 
                  onChange={(e) => {
                    formik.setFieldValue("IDSubCategory", e.value)
                  }}
                  onBlur={formik.handleBlur}
                  placeholder={t('SubCategory_placeholder')}
                  className="w-full p-inputtext-sm"
                />
              </div>
            }
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <label htmlFor="BrandProductTitleEn" className="font-bold block mb-2">{t('label_categories_nameEn')}</label>
              <InputText
                name='BrandProductTitleEn'
                id="BrandProductTitleEn"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}


              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="BrandProductTitleAr" className="font-bold block mb-2">{t('label_categories_nameAr')}</label>
              <InputText
                name='BrandProductTitleAr'
                id="BrandProductTitleAr"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}



              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="BrandProductPrice" className="font-bold block mb-2">{t('price')}</label>
              <InputText
                name='BrandProductPrice'
                id="BrandProductPrice"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}

              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="BrandProductOfferPrice" className="font-bold block mb-2">{t('discount')}</label>
              <InputText
                name='BrandProductOfferPrice'
                id="BrandProductOfferPrice"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}



              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="BrandProductDescEn" className="font-bold block mb-2">{t('desc_en')}</label>
              <InputTextarea
                name='BrandProductDescEn'
                id="BrandProductDescEn"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}


              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="BrandProductDescAr" className="font-bold block mb-2">{t('desc_ar')}</label>
              <InputTextarea
                name='BrandProductDescAr'
                id="BrandProductDescAr"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}


              />
            </div>

          </div>

          <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
            <div className="btn_submit">
              <Button severity="danger" raised icon="pi pi-check" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
            </div>
            <Link to={'/products'}>
              <Button label={t('cancel')} severity="danger" type="reset" outlined size='small' className='mt-3' />
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddProducts