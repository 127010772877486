
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { ToggleButton } from 'primereact/togglebutton';
import { Toolbar } from 'primereact/toolbar';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const Products = () => {

  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [Data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [pagesNum, setPagesNum] = useState(null);
  // Add new brands
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to="/products/add">
          <Button label={t('products_add')} severity="danger" icon="pi pi-plus" size='small' />
        </Link>

      </div>
    );
  };
  // logcation path 
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('logPath', currentPath)
  }, [])
  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2 justify-content-center align-content-center">

        <Link to={`/products/edit/${rowData.IDBrandProduct}`}>
          <Button icon="pi pi-pencil" severity="danger" rounded outlined text raised className="mr-2" />
        </Link>
      </div>
    );
  };
  const filterType = [
    { name: t('reset'), value: 'ALL' },
    { name: t('CLOTHES'), value: 'CLOTHES' },
    { name: t('COSMETICS'), value: 'COSMETICS' },
  ];
  const [selectedTypeFilter, setTypeFilter] = useState(null);
  const [typeFilter, setType] = useState('');

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <div>
        <Dropdown
          value={selectedTypeFilter}
          options={filterType}
          id="CategoryType"
          name="CategoryType"
          optionLabel="name"
          optionValue="value"
          onChange={(e) => {
            setTypeFilter(e.value)
            if (e.value === "ALL") {
              setType('')
              setTypeFilter(null)
            } else {
              setType(e.value)
            }
          }}
          placeholder={isLang === "en" ? 'All' : 'الجميع  '}
          className="p-inputtext-sm w-14rem"
        />
      </div>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
      </span>
    </div>
  );

  const fetchData = useCallback(async () => {
    setData([])
    const url = `${process.env.REACT_APP_API_URL}/brands/products`;
    let data = await axios.post(url, { IDPage: page + 1, CategoryType: typeFilter },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.BrandProducts);
    setPagesNum(data?.data?.Response?.Pages)
  }, [typeFilter,page]);

  useEffect(() => {
    fetchData();
  }, [isLang, page,typeFilter])


  const [editingRow, setEditingRow] = useState(null);
  const [BrandProductFeaturedRow, setEditingRowTop] = useState(null);
  const [brandProductDeal, setBrandProductDeal] = useState(null);

  const statusOptions = [
    { label: t('active'), value: 'ACTIVE' },
    { label: t('inactive'), value: 'INACTIVE' },
  ];

  const topOptions = [
    { label: t('Featured'), value: 1 },
    { label: t('normal'), value: 0 },
  ];

  const dealOptions = [
    { label: t('deal'), value: 1 },
    { label: t('notDeal'), value: 0 },
  ];
  const onStatusChange = async (rowData, e) => {
    setEditingRow(null);
    await axios.post(`${process.env.REACT_APP_API_URL}/brands/products/status`, { IDBrandProduct: rowData?.IDBrandProduct, BrandProductStatus: e?.value }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };
  const BrandProductFeaturedChange = async (rowData, e) => {
    setEditingRowTop(null);
    await axios.get(`${process.env.REACT_APP_API_URL}/brands/products/featured/${rowData?.IDBrandProduct}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };
  const BrandProductDealChange = async (rowData, e) => {
    setBrandProductDeal(null);
    await axios.get(`${process.env.REACT_APP_API_URL}/brands/products/deal/${rowData?.IDBrandProduct}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }else{
        toast.current.show({ severity: 'error', summary: 'Invalid', detail: data?.ApiMsg, life: 3000 });

      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };


  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.BrandProductStatus === 'ACTIVE' ? t('active') : rowData?.BrandProductStatus === 'INACTIVE' ? t('inactive') : t('pending')

    return editingRow === rowData?.IDBrandProduct ? (
      <Dropdown
        value={editingStatus}
        options={statusOptions.filter((option) => option.value !== rowData?.BrandProductStatus)}
        onChange={(e) => onStatusChange(rowData, e)}
        placeholder={`${editingStatus}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag rounded
        value={editingStatus}
        severity={getSeverity(rowData.BrandProductStatus)}
        onClick={() => setEditingRow(rowData?.IDBrandProduct)}
      />
    );
  };

  const statusBodyTemplate1 = (rowData) => {
    const editingStatus = rowData?.BrandProductFeatured === 1 ? t('Featured') : t('normal');
    const placeholderText = rowData?.BrandProductFeatured !== 1 ? t('normal') : t('Featured');

    return BrandProductFeaturedRow === rowData?.IDBrandProduct ? (
      <Dropdown
        value={editingStatus}
        options={topOptions.filter((option) => option.value !== rowData?.BrandProductFeatured)}
        onChange={(e) => BrandProductFeaturedChange(rowData, e)}
        placeholder={`${placeholderText}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag rounded
        value={editingStatus}
        severity={getSeverityTop(rowData.BrandProductFeatured)}
        onClick={() => setEditingRowTop(rowData?.IDBrandProduct)}
      />
    );
  };
  const statusBodyTemplate2 = (rowData) => {
    const editingStatus = rowData?.BrandProductDeal === 1 ? t('deal') : t('notDeal');
    const placeholderText = rowData?.BrandProductDeal !== 1 ? t('notDeal') : t('deal');

    return brandProductDeal === rowData?.IDBrandProduct ? (
      <Dropdown
        value={editingStatus}
        options={dealOptions.filter((option) => option.value !== rowData?.BrandProductDeal)}
        onChange={(e) => BrandProductDealChange(rowData, e)}
        placeholder={`${placeholderText}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag rounded
        value={editingStatus}
        severity={getSeverityDeal(rowData.BrandProductDeal)}
        onClick={() => setBrandProductDeal(rowData?.IDBrandProduct)}
      />
    );
  };

  const getSeverity = (status) => {
    switch (status) {
      case 'INACTIVE':
        return 'danger';

      case 'ACTIVE':
        return 'success';

      case 'PENDING':
        return 'warning';
      case '':
        return 'info';
    }
  };

  const getSeverityTop = (status) => {
    switch (status) {
      case 0:
        return 'danger';

      case 1:
        return 'success';

      case '':
        return 'info';
    }
  };

  const getSeverityDeal = (status) => {
    switch (status) {
      case 0:
        return 'danger';

      case 1:
        return 'success';

      case '':
        return 'info';
    }
  };
  // handel image in tabel
  const imageBodyTemplate = (rowData) => {
    return <div className='flex justify-content-center'>
      <Image
        preview
        loading="lazy"
        src={rowData.BrandProductLogo}
        alt={'brnad iamge'}
        width='90'
        height='100%'
        className=' mx-auto overflow-hidden shadow-3  border-round' style={{ objectFit: 'cover' }}
      />
    </div>
  };

  const homeBodyTemplate = (rowData) => (
    <ToggleButton
      size='small'
      className={`w-2rem ${rowData.HomeBrandProduct === 1 ? 'bg-green-500 border-none p-1 text-sm' : 'bg-red-500 border-none p-1 text-0  text-sm'}`}
      checked={rowData.HomeBrandProduct === 1}
      onChange={() => handleToggle(rowData)}
    />
  );
  const [checkedItems, setCheckedItems] = useState({});
  const handleToggle = async (rowData) => {
    const updatedCheckedItems = { ...checkedItems, [rowData.id]: !checkedItems[rowData.id] };
    setCheckedItems(updatedCheckedItems);
    await axios.get(`${process.env.REACT_APP_API_URL}/brands/products/home/${rowData?.IDBrandProduct}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })

  };



  const text = (text) => {
    if (text?.CategoryType === "CLOTHES") {
      return <span>{t('CLOTHES')}</span>
    } else if (text?.CategoryType === "COSMETICS") {
      return <span>{t('COSMETICS')}</span>
    }
  }
  return (
    <div  >
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card">

        <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>

        <DataTable className="custom-datatable" scrollable scrollHeight="650px"
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}
          rowHover
          showGridlines
          header={header}>
          <Column field="BrandProductLogo" header={t('image')} body={imageBodyTemplate}></Column>
          <Column field="BrandProductTitle" header={t('name')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          {/* <Column field="BrandProductDesc" header={t('desc')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
          <Column field="CategoryName" header={t('categories_title')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column field="SubCategoryName" header={isLang === "en" ? 'Sub Category' : 'تصنيف فرعي'} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column field="CategoryType" body={text} header={t('type')} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
          <Column field="BrandName" header={t('brand')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column field="BrandProductPrice" header={t('price')} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
          <Column field="BrandProductOfferPrice" header={t('discount')} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
          <Column field="HomeBrandProduct" header={t('home')} body={homeBodyTemplate} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
          <Column field="BrandProductFeatured" header={t('ProductFeatured')} body={statusBodyTemplate1} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column field="BrandProductDeal" header={t('Productdeal')} body={statusBodyTemplate2} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
          <Column field="BrandProductRating" header={t('Rating')} style={{ minWidth: '7rem', textAlign: 'center' }}></Column>
          <Column field="BrandProductStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }}></Column>

        </DataTable>
        <Paginator dir='ltr'
          first={page * 10} // Calculate the first record index based on the page
          rows={10}
          totalRecords={pagesNum * 10}
          onPageChange={(e) => setPage(e.page)}
          template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
        />
      </div>

    </div>
  )
}


export default Products
