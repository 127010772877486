import axios from 'axios'
import { LocalizationContext } from 'context/LangChange'
import { format, isValid, parseISO } from 'date-fns'
import { BreadCrumb } from 'primereact/breadcrumb'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Tag } from 'primereact/tag'
import { Toast } from 'primereact/toast'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

const OrderDetails = () => {
    let { id } = useParams()
    let { t } = useTranslation()
    const toast = useRef(null);
    const dt = useRef(null);
    let { isLang } = useContext(LocalizationContext);
    const [Data, setData] = useState(null);
    const [address, setAddress] = useState(null);
    const [deleteLoadind, setdeleteLoadind] = useState(false);
    const [editingRow, setEditingRow] = useState(null);
    const [deleteusersDialog, setDeleteusersDialog] = useState(false);
    const [statusChange, setStatusChange] = useState('');
    const hideDeleteusersDialog = () => setDeleteusersDialog(false);

    const fetchData = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/orders/details/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        if (data?.Success === true) {
            setData(data?.Response[0]);
            setAddress([data?.Response[0]?.OrderAddress]);
        }
    }
    useEffect(() => {
        fetchData();
        window.scroll(0, 0)
    }, [isLang, id])
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    const getSeverity = (status) => {
        switch (status) {
            case 'OUT_FOR_DELIVERY':
                return 'help';
            case 'REJECTED':
                return 'danger';
            case 'DELIVERED':
                return 'success';
            case 'ARRIVED_AT_STATION':
                return 'Info';
            case 'PENDING':
                return 'warning';
            case 'ACCEPTED':
                return 'success';
            case 'CANCELLED':
                return 'danger';
            case 'RETURNED':
                return 'danger';
        }
    };

    const items = [
        { label: <Link to={`/orders`} className='text-bluegray-400'> {t('order')}</Link> },
        { label: <Link to={`/orders/details/${id}`} className='  text-bluegray-800 p-2 border-round'>{t('orderDetails')}</Link> }
    ];
    const mainAddress = (item) => {
        console.log(item);
        if (item?.MainAddress == 1) {
            return <i className='pi pi-check-circle  '></i>
        } else {
            return <i className='pi pi-times-circle  '></i>

        }
    }
    const home = { icon: 'pi pi-home', url: '/' };
    const [globalFilter, setGlobalFilter] = useState(null);
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">{t('CLOTHES')}</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );

    const header2 = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">{t('COSMETICS')}</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );
    const price = (data) => {
        if (data?.OrderDetailOfferPrice === 0) {
            return (<span>{data?.OrderDetailPrice}</span>)
        } else {
            return (<span>{data?.OrderDetailOfferPrice}</span>)
        }
    };
    const deleteusers = async (rowData, e) => {
        setdeleteLoadind(true)

        await axios.post(`${process.env.REACT_APP_API_URL}/orders/status`, {
            IDOrder: id,
            OrderStatus: statusChange
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
                setDeleteusersDialog(false);
                setdeleteLoadind(false)
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setdeleteLoadind(false)

        })
    };
    const deleteusersDialogFooter = (
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button size='small' label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" severity="danger" outlined onClick={hideDeleteusersDialog} />
            <div className="btn_submit">
                <Button size='small' label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} raised icon="pi pi-check" severity="danger" onClick={deleteusers} />
            </div>
        </div>
    );
    const getStatusOptions = (status) => {
        switch (status) {
            case 'PENDING':
                return [
                    { label: t('ACCEPT'), value: 'ACCEPTED' },
                    { label: t('REJECT'), value: 'REJECTED' }
                ];
            case 'ACCEPTED':
                return [
                    { label: t('OUT_FOR_DELIVERY'), value: 'OUT_FOR_DELIVERY' },
                    /*          { label: t('ARRIVED_AT_STATION'), value: 'ARRIVED_AT_STATION' },
                             { label: t('DELIVERED'), value: 'DELIVERED' } */
                ];
            case 'OUT_FOR_DELIVERY':
                return [
                    { label: t('ARRIVED_AT_STATION'), value: 'ARRIVED_AT_STATION' },
                    // { label: t('DELIVERED'), value: 'DELIVERED' }
                ];
            case 'ARRIVED_AT_STATION':
                return [
                    { label: t('DELIVERED'), value: 'DELIVERED' }
                ];
            case 'DELIVERED':
                return [
                    { label: t('ARRIVED_AT_STATION'), value: 'ARRIVED_AT_STATION' },
                    { label: t('DELIVERED'), value: 'DELIVERED' }
                ];
            default:
                return [];
        }
    };
    const statusBodyTemplate = (status) => {
        const options = getStatusOptions(status);
        const editingStatus =
            status === 'ACCEPTED' ? t('ACCEPTED') :
                status === 'CANCELLED' ? t('CANCELLED') :
                    status === 'REJECTED' ? t('REJECTED') :
                        status === 'OUT_FOR_DELIVERY' ? t('OUT_FOR_DELIVERY') :
                            status === 'ARRIVED_AT_STATION' ? t('ARRIVED_AT_STATION') :
                                status === 'CANCELLED' ? t('CANCELLED') :
                                    status === 'RETURNED' ? t('RETURNED') :
                                        status === 'DELIVERED' ? t('DELIVERED') : t('pending');

        return editingRow === id ? (
            <Dropdown
                value={status}
                options={options}
                onChange={(e) => onStatusChange(status, e)}
                placeholder={editingStatus}
                className="p-inputtext-sm dropdown_filter "
                style={{ fontSize: '12px', lineHeight: '20px' }}
            />
        ) : (
            <>
                <Tag
                    rounded
                    severity={getSeverity(status)}
                    onClick={() => {
                        if (
                            status === 'ACCEPTED' ||
                            status === 'OUT_FOR_DELIVERY' ||
                            status === 'ARRIVED_AT_STATION' ||
                            status === 'PENDING'
                        ) {
                            setEditingRow(id);
                        }
                    }}

                >
                    <div className='flex justify-content-center align-items-center gap-2'>
                        <span> {editingStatus}</span>
                        <i className='pi pi-angle-down'></i>

                    </div>
                </Tag>
            </>
        );
    };
    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        setDeleteusersDialog(true);
        setStatusChange(e.value)
    };

    return (
        <>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <div className="mb-3 px-5 mt-3">
                <BreadCrumb model={items} home={home} />
            </div>
            <div className="app__PersonalData px-6 mt-0 pt-0">

                <div className="info flex flex-row gap-8 mt-35">
                    {/* <Tag rounded
                        value={`${Data?.OrderStatus === "DELIVERED" ? t('DELIVERED') : Data?.OrderStatus === "OUT_FOR_DELIVERY" ? t('OUT_FOR_DELIVERY') : Data?.OrderStatus === "ONGOING" ? t('ONGOING') : Data?.OrderStatus === "CANCELLED" ? t('CANCELLED') : Data?.OrderStatus === "CANCELLED" ? t('CANCELLED') : Data?.OrderStatus === "ACCEPTED" ? t('ACCEPTED') : t('pending')}`}
                        severity={getSeverity(Data?.OrderStatus)}
                    /> */}
                    {statusBodyTemplate(Data?.OrderStatus)}
                </div>
                <div className="info_contactus mt-35">
                    <div className="grid  mt-5  ">
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-user'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('name')}   </div>
                                    <div className="body link" dir='ltr'>
                                        <Link to={`/clients/details/${Data?.IDClient}`} style={{ textDecoration: 'underline' }} >
                                            {Data?.ClientName}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-phone'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('phone')}   </div>
                                    <div className="body" dir='ltr'> {Data?.ClientPhone}  </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-calendar-plus'></i>
                                </div>
                                <div>
                                    <div className="header"> {isLang === "en" ? 'Order Date' : 'تاريخ الطلب'}   </div>
                                    <div className="body" dir='ltr'>
                                        {isValid(parseISO(Data?.OrderDate)) ? format(parseISO(Data?.OrderDate), 'dd-MM-yyyy HH:mm') : 'Invalid Date'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-shopping-cart'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('ShippingAmount')}   </div>
                                    <div className="body" dir='ltr'>
                                        {Data?.OrderShippingAmount}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-dollar'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('amount')}   </div>
                                    <div className="body" dir='ltr'>
                                        {Data?.OrderAmount}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-gift'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('discount')}   </div>
                                    <div className="body" dir='ltr'>
                                        {Data?.OrderOfferAmount}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi  pi-money-bill'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('totalAmount')}   </div>
                                    <div className="body" dir='ltr'>
                                        {Data?.OrderTotalAmount}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-wallet'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('ledger_Batchnumber')}   </div>
                                    <div className="body" dir='ltr'>
                                        {Data?.OrderBatchNumber}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            Data?.OrderStatus === "RETURNED" &&
                            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                                <div className="conatent ">
                                    <div className="icons">
                                        <i className='pi pi-envelope'></i>
                                    </div>
                                    <div>
                                        <div className="header"> {t('OrderReturnReason')}   </div>
                                        <div className="body" dir='ltr'>
                                            {Data?.OrderReturnReason}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="info_contactus mt-4">
                    <div className="conatent d-flex   align-items-center ">
                        <div className="icons">
                            <i className='pi pi-map-marker'></i>
                        </div>
                        <div>
                            <div className="header_gallery_Tool"> <span className='font-bold fs-1 m-0 p-0'>{t('addres')}</span>   </div>
                        </div>
                    </div>
                    <div className="card mb-4">
                        <DataTable
                            className='custom-datatable'
                            scrollable
                            scrollHeight='550px'
                            value={address}
                            dataKey='id'
                            showGridlinesD
                            globalFilter={globalFilter}

                        >
                            <Column field="AddressName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                            <Column field="ClientAddress" header={t('addres')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                            <Column field="AddressPhone" header={t('phone')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                            <Column field="MainAddress" header={t('main')} body={mainAddress} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                            <Column field="CountryName" header={t('countries_title')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                            <Column field="CityName" header={t('CityName')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                            <Column field="AreaName" header={t('AreaName')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        </DataTable>

                    </div>
                </div>
                <div className="pt-5 pb-5">
                    <hr />
                </div>
                <div className="card">

                    <DataTable
                        className='custom-datatable'
                        scrollable
                        scrollHeight='550px'
                        value={Data?.OrderDetailsClothes}
                        dataKey='id'
                        showGridlines
                        globalFilter={globalFilter}
                        selectionMode='single' // or 'multiple'
                        header={header}
                    // onRowSelect={handleRowSelect}
                    >
                        <Column field="CategoryName" header={t('categories_title')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        <Column field="SubCategoryName" header={t('subcategories_title')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        <Column field="BrandName" header={t('brand')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        <Column field="BrandProductTitle" header={t('BrandProductTitle')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        {/* <Column field="OrderDetailOfferPrice" header={t('offer')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                        <Column field="OrderDetailQuantity" header={t('OrderDetailQuantity')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        <Column field="OrderDetailPrice" header={t('price')} body={price} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        {/* <Column field="OrderDetailTotalAmount" header={t('totalAmount')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                        {/* <Column field="BrandProductTitle" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    </DataTable>

                </div>

                <div className="pt-5 pb-5">
                    <hr />
                </div>
                <div className="card">

                    <DataTable
                        className='custom-datatable'
                        scrollable
                        scrollHeight='550px'
                        value={Data?.OrderDetailsCosmetics}
                        dataKey='id'
                        showGridlines
                        globalFilter={globalFilter}
                        selectionMode='single' // or 'multiple'
                        header={header2}>
                        <Column field="CategoryName" header={t('categories_title')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        <Column field="SubCategoryName" header={t('subcategories_title')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        <Column field="BrandName" header={t('brand')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        <Column field="BrandProductTitle" header={t('BrandProductTitle')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        {/* <Column field="OrderDetailOfferPrice" header={t('offer')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                        <Column field="OrderDetailQuantity" header={t('OrderDetailQuantity')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        <Column field="OrderDetailPrice" header={t('price')} body={price} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        {/* <Column field="OrderDetailTotalAmount" header={t('totalAmount')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                        {/* <Column field="BrandProductTitle" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    </DataTable>

                </div>





            </div >

            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} modal className="p-fluid" visible={deleteusersDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Change Status' : 'تأكيد الحذف'} footer={deleteusersDialogFooter} onHide={hideDeleteusersDialog}>
                <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>
                        {isLang === "en" ? "Are you sure you want to change the order status?  " : 'هل أنت متأكد أنك تريد تغيير حالة الطلب؟  '}
                    </span>
                </div>
            </Dialog>

        </>
    )
}

export default OrderDetails
