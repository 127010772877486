
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import BrandRating from './BrandRating';
import { ToggleButton } from 'primereact/togglebutton';
import { Tooltip } from 'primereact/tooltip';

const Brand = () => {

    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);

    // Add new brands
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Link to="/brands/add">
                    <Button label={t('brand_add')} severity="danger" icon="pi pi-plus" size='small' />
                </Link>

            </div>
        );
    };
    // logcation path 
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('logPath', currentPath)
    }, [])
    const [modalRating, setModalRating] = useState(false);
    const [dataSocialMedia, setdataSocialMedia] = useState(null);

    const handelRating = (data) => {
        setdataSocialMedia(data)
        setModalRating(true)
    }
    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (

            <div className="flex gap-2 justify-content-center align-content-center">
                <Tooltip target=".disabled-button" autoHide={false} />
                <Link to={`/brands/edit/${rowData.IDBrand}`}>
                    <Button  tooltip={t('brand_edit')} icon="pi pi-pencil" severity="danger" tooltipOptions={{ position: t('tooltip') }} rounded outlined text raised className="mr-2" />
                </Link>
                <Button  tooltip={t('Rating')}  tooltipOptions={{ position: t('tooltip') }}  icon="pi pi-star" severity="danger" onClick={() => handelRating(rowData)} rounded outlined text raised className="mr-2" />
            </div>
        );
    };
    const filterType = [
        { name: t('reset'), value: 'ALL' },
        { name: t('CLOTHES'), value: 'CLOTHES' },
        { name: t('COSMETICS'), value: 'COSMETICS' },
      ];
      const [selectedTypeFilter, setTypeFilter] = useState(null);
      const [typeFilter, setType] = useState('');
    
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
          <div>
        <Dropdown
          value={selectedTypeFilter}
          options={filterType}
          id="CategoryType"
          name="CategoryType"
          optionLabel="name"
          optionValue="value"
          onChange={(e) => {
            setTypeFilter(e.value)
            if (e.value === "ALL") {
              setType('')
              setTypeFilter(null)
            } else {
              setType(e.value)
            }
          }}
          placeholder={isLang === "en" ? 'All' : 'الجميع  '}
          className="p-inputtext-sm w-14rem"
        />
      </div>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );

    const fetchData = useCallback(async () => {
        setData([])
        const url = `${process.env.REACT_APP_API_URL}/brands`;

        let data = await axios.post(url, { IDPage: page + 1, BrandType: typeFilter },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.Brands);
        setPagesNum(data?.data?.Response?.Pages)
    }, [typeFilter]);

    useEffect(() => {
        fetchData();
    }, [isLang, page,typeFilter])


    const [editingRow, setEditingRow] = useState(null);
    const [topBrandRow, setEditingRowTop] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 'ACTIVE' },
        { label: t('inactive'), value: 'INACTIVE' },
    ];

    const topOptions = [
        { label: t('top'), value: 1 },
        { label: t('down'), value: 0 },
    ];
    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        await axios.post(`${process.env.REACT_APP_API_URL}/brands/status`, { IDBrand: rowData?.IDBrand, BrandStatus: e?.value }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };
    const topBrandChange = async (rowData, e) => {
        setEditingRowTop(null);
        await axios.get(`${process.env.REACT_APP_API_URL}/brands/top/${rowData?.IDBrand}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };
    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.BrandStatus === 'ACTIVE' ? t('active') : rowData?.BrandStatus === 'INACTIVE' ? t('inactive') : t('pending')

        return editingRow === rowData?.IDBrand ? (
            <Dropdown
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.BrandStatus)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${editingStatus}`}
                className="p-inputtext-sm "
            />
        ) : (
            <Tag rounded
                value={editingStatus}
                severity={getSeverity(rowData.BrandStatus)}
                onClick={() => setEditingRow(rowData?.IDBrand)}
            />
        );
    };

    const statusBodyTemplate1 = (rowData) => {
        const editingStatus = rowData?.TopBrand === 1 ? t('top') : t('down');
        const placeholderText = rowData?.TopBrand !== 1 ? t('down') : t('top');

        return topBrandRow === rowData?.IDBrand ? (
            <Dropdown
                value={editingStatus}
                options={topOptions.filter((option) => option.value !== rowData?.TopBrand)}
                onChange={(e) => topBrandChange(rowData, e)}
                placeholder={`${placeholderText}`}
                className="p-inputtext-sm "
            />
        ) : (
            <Tag rounded
                value={editingStatus}
                severity={getSeverityTop(rowData.TopBrand)}
                onClick={() => setEditingRowTop(rowData?.IDBrand)}
            />
        );
    };



    const getSeverity = (status) => {
        switch (status) {
            case 'INACTIVE':
                return 'danger';

            case 'ACTIVE':
                return 'success';

            case 'PENDING':
                return 'warning';
            case '':
                return 'info';
        }
    };

    const getSeverityTop = (status) => {
        switch (status) {
            case 0:
                return 'danger';

            case 1:
                return 'success';

            case '':
                return 'info';
        }
    };
    // handel image in tabel
    const imageBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center'>
            <Image
                preview
                loading="lazy"
                src={rowData.BrandLogo}
                alt={'brnad iamge'}
                width='90'
                height='100%'
                className=' mx-auto overflow-hidden shadow-3  border-round' style={{ objectFit: 'cover' }}
            />
        </div>
    };
    const homeBodyTemplate = (rowData) => (
        <ToggleButton
            size='small'
            className={`w-3rem ${rowData.HomeBrand === 1 ? 'bg-green-500 border-none p-1 text-sm' : 'bg-red-500 border-none p-1 text-0  text-sm'}`}
            checked={rowData.HomeBrand === 1}
            onChange={() => handleToggle(rowData)}
        />
    );
    const [checkedItems, setCheckedItems] = useState({});
    const handleToggle = async (rowData) => {
        const updatedCheckedItems = { ...checkedItems, [rowData.id]: !checkedItems[rowData.id] };
        setCheckedItems(updatedCheckedItems);
        await axios.get(`${process.env.REACT_APP_API_URL}/brands/home/${rowData?.IDBrand}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })

    };


    const text = (text) => {
        if (text?.BrandType === "CLOTHES") {
            return <span>{t('CLOTHES')}</span>
        } else if (text?.BrandType === "COSMETICS") {
            return <span>{t('COSMETICS')}</span>
        }
    }

    return (
        <div  >
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">

                <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>

                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    rowHover
                    showGridlines
                    header={header}>
                    <Column field="BrandLogo" header={t('logo')} body={imageBodyTemplate}></Column>
                    <Column field="BrandName" header={t('name')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    {/* <Column field="BrandDesc" header={t('desc')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    <Column field="BrandNumber" header={t('phone')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="BrandEmail" header={t('email')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="BrandType" header={t('type')} body={text} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column  field="HomeBrand" header={t('home')} body={homeBodyTemplate} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>

                    <Column  field="TopBrand" header={t('top_brand')} body={statusBodyTemplate1} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                    <Column  field="BrandStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }}></Column>

                </DataTable>
                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>
            <BrandRating
                modalDone={modalRating}
                setModalDone={setModalRating}
                dataSocialMedia={dataSocialMedia}
            />
        </div>
    )
}


export default Brand