import axios from 'axios'
import { LocalizationContext } from 'context/LangChange'
import { format, isValid, parseISO } from 'date-fns'
import { BreadCrumb } from 'primereact/breadcrumb'
import { InputText } from 'primereact/inputtext'
import { Tag } from 'primereact/tag'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import './client.scss'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'

const ClientsDetails = () => {
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    let { id } = useParams()
    const [Data, setData] = useState(null);
    const [address, setAddress] = useState(null);

    const fetchData = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/clients/details/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        if (data?.Success === true) {
            setData(data?.Response);
            setAddress(data?.Response?.ClientAddresses);
        }
    }
    useEffect(() => {
        fetchData();
        window.scroll(0, 0)
    }, [isLang, id])
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const getSeverity = (status) => {
        switch (status) {
            case 'ACTIVE':
                return 'success';

            case 'INACTIVE':
                return 'danger';

            case 'PENDING':
                return 'warning';

            case 'CANCELLED':
                return 'danger';
            case 'NOT_VERIFIED':
                return 'danger';

            case 'ONGOING':
                return 'warning';


        }
    };

    const items = [
        { label: <Link to={`/clients`} className='text-bluegray-400'> {t('client_name')}</Link> },
        { label: <Link to={`/clients/details/${id}`} className='  text-bluegray-800 p-2 border-round'>{t('client_details')}</Link> }
    ];
    const home = { icon: 'pi pi-home', url: '/' };
    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0"> </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );

    const mainAddress = (item) => {
        console.log(item);
        if (item?.MainAddress == 1) {
            return <i className='pi pi-check-circle  '></i>
        } else {
            return <i className='pi pi-times-circle  '></i>

        }
    }

    return (
        <>
            <div className="mb-3 px-7 mt-3">
                <BreadCrumb model={items} home={home} />
            </div>
            <div className="app__PersonalData px-8 mt-0 pt-0">

                <div className="info flex flex-row gap-8 mt-35">
                    <Tag rounded
                        value={`${Data?.ClientStatus === "ACTIVE" ? t('active') : Data?.ClientStatus === "INACTIVE" ? t('inactive') : Data?.ClientStatus === "ONGOING" ? t('ONGOING') : Data?.ClientStatus === "CANCELLED" ? t('CANCELLED') : Data?.ClientStatus === "CANCELLED" ? t('CANCELLED') : Data?.ClientStatus === "NOT_VERIFIED" ? t('NOT_VERIFIED') : t('pending')}`}
                        severity={getSeverity(Data?.ClientStatus)}
                    />
                </div>
                <div className="info_contactus mt-35">
                    <div className="grid  mt-5  ">

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-user'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('name')}   </div>
                                    <div className="body" dir='ltr'> {Data?.ClientName}  </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-envelope'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('email')}   </div>
                                    <div className="body" dir='ltr'> {Data?.ClientEmail}  </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-phone'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('phone')}   </div>
                                    <div className="body" dir='ltr'> {Data?.ClientPhone}  </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-calendar-plus'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('created_at')}   </div>
                                    <div className="body" dir='ltr'>
                                        {isValid(parseISO(Data?.RegisterDate)) ? format(parseISO(Data?.RegisterDate), 'dd-MM-yyyy') : 'Invalid Date'}
                                    </div>                                </div>
                            </div>
                        </div>





                    </div>
                </div>
                <div className="pt-5 pb-5">
                    <hr />
                </div>
                <div className="info_contactus mt-35">
                    <div className="conatent d-flex   align-items-center ">
                        <div className="icons">
                            <i className='pi pi-map-marker'></i>
                        </div>
                        <div>
                            <div className="header_gallery_Tool"> <span className='font-bold fs-1 m-0 p-0'>{t('addres')}</span>   </div>
                        </div>
                    </div>
                    <div className="card mt-2">

                        <DataTable
                            className='custom-datatable'
                            scrollable
                            scrollHeight='550px'
                            value={address}
                            dataKey='id'
                            showGridlines
                            globalFilter={globalFilter}
                            selectionMode='single' // or 'multiple'
                            header={header}
                        >
                            <Column field="AddressName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                            <Column field="ClientAddress" header={t('addres')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                            <Column field="AddressPhone" header={t('phone')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                            <Column field="MainAddress" header={t('main')} body={mainAddress} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                            <Column field="CountryName" header={t('countries_title')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                            <Column field="CityName" header={t('CityName')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                            <Column field="AreaName" header={t('AreaName')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        </DataTable>

                    </div>
                </div>





            </div>
        </>
    )
}


export default ClientsDetails
