import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const UpdateCountries = () => {
  let { id } = useParams()
  const toast = useRef(null);
  let navigate = useNavigate();
  let { isLang } = useContext(LocalizationContext);
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/location/countries/edit`;
  let { t } = useTranslation()

  const [data, setData] = useState([])
  const statusList = [
    { name: t('active'), value: '1' },
    { name: t('inactive'), value: '0' },
  ];
  async function getdata() {

    const url = `${process.env.REACT_APP_API_URL}/location/countries/edit/page/${id}`;
    let { data } = await axios.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
        // 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      }
    })
    if (data?.Success) {
      setData(data?.Response)
    }
    console.log(data?.Response);

  }

  const [loading, setLoading] = useState(false);

  const items = [
    { label: <Link to={'/countries'}> {t('countries_title')}  </Link> },
    { label: <Link to={`/countries/edit/${id}`} className='p-2 border-round'>   {t('edit_countries')} </Link> }
  ];

  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      IDCountry: id || '',
      CountryNameEn: data?.CountryNameEn || '',
      CountryNameAr: data?.CountryNameAr || '',
      CountryCurrency: data?.CountryCurrency || '', 
    },
    onSubmit: async (values, { resetForm }) => {
      
      try {
        setLoading(true);

        let { data } = await axios.post(urlUpdateData, values,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/countries')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        setLoading(false);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);

      }
    }
  });


  useEffect(() => {
    getdata()
  }, [id]);

  useEffect(() => {
    formik.setValues({
      IDCountry: id || '',
      CountryNameEn: data?.CountryNameEn || '',
      CountryNameAr: data?.CountryNameAr || '',
      CountryCurrency: data?.CountryCurrency || '', 

    });
  }, [data, formik.setValues]);
  return (
    <div className='mb-8'>     
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="CountryNameEn" className="font-bold block mb-2"> {t('label_CountryNameEn')}   </label>
            <InputText
              name='CountryNameEn'
              value={formik.values.CountryNameEn}
              id="CountryNameEn" type='text'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full  p-inputtext-sm"
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="CountryNameAr" className="font-bold block mb-2">   {t('label_CountryNameAr')}  </label>
            <InputText
              name='CountryNameAr'
              value={formik.values.CountryNameAr}
              id="CountryNameAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>




        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised severity="warning"   icon="pi pi-check" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/countries'}>
            <Button raised severity="warning" label={t('cancel')} type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>

    </div>
  )
}

export default UpdateCountries
