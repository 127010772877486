import axios from 'axios';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FetchApi } from 'context/FetchApi';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from 'context/LangChange';
import { useEffect } from 'react';

const AddAreas = () => {
  let { isLang } = useContext(LocalizationContext);
  let { city, country, fetchCity, fetchCountry, setCity } = useContext(FetchApi)
  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/location/areas/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
    { label: <Link to={'/areas'}>  {t('areas_title')} </Link> },
    { label: <Link to={'/areas/add'} className='p-2 border-round'> {t('add_areas')} </Link> }
  ];
  const statusList = [
    { name: t('active'), value: '1' },
    { name: t('inactive'), value: '0' },
  ];
  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      AreaNameEn: '',
      AreaNameAr: '',
      AreaActive: '1',
      IDCity: '',
      AreaShippingPrice: '',

    },
    onSubmit: async (values, { resetForm }) => {

      try {
        setLoading(true);
        let { data } = await axios.post(url, values,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })
        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/areas')
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });


  useEffect(() => {
    fetchCity(1)
    formik.setFieldValue('IDCity',city[0]?.IDCity)
    return () => {
      fetchCity(1)
    }
  }, [isLang])

  return (
    <>
      <div className='mb-8'>
        <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

        <BreadCrumb model={items} home={home} />
        <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

          <div className="grid  mt-5  ">
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <label htmlFor="AreaNameEn" className="font-bold block mb-2"> {t('label_areas_nameEn')}   </label>
              <InputText
                name='AreaNameEn'
                id="AreaNameEn"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="AreaNameAr" className="font-bold block mb-2">   {t('label_areas_nameAr')}  </label>
              <InputText name='AreaNameAr' id="AreaNameAr" type='text' className="w-full  p-inputtext-sm" onChange={formik.handleChange}
                onBlur={formik.handleBlur} />
            </div>
          </div>

          <div className="grid  mt-3  ">
            <div className="lg:col-6 md:col-12 sm:col-12      ">
              <label htmlFor="IDCity" className="font-bold block mb-2"> {t('tabel-header-cities-1')}</label>
              <Dropdown filter
                options={city?.map(item => ({
                  name: item.CityName,
                  value: item.IDCity,
                }))}
                id="IDCity"
                name="IDCity"
                optionLabel="name"
                optionValue="value"
                value={formik.values.IDCity} // Add this line 
                onChange={(e) => {
                  formik.setFieldValue("IDCity", e.value)
                }}
                onBlur={formik.handleBlur}
                placeholder={t('city_placeholder')}
                className="w-full p-inputtext-sm"
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   input__Col" >
              <label htmlFor="AreaShippingPrice" className="font-bold block mb-2">   {t('CityShippingPrice')}  </label>
              <InputText name='AreaShippingPrice' id="AreaShippingPrice" type='text' className="w-full  p-inputtext-sm" onChange={formik.handleChange}
                onBlur={formik.handleBlur} />
            </div>
            {/* <div className="lg:col-6 md:col-12 sm:col-12      ">
              <label htmlFor="IDCountry" className="font-bold block mb-2"> {t('tabel-header-Countries-1')}</label>
              <Dropdown
                options={country?.map(item => ({
                  name: item.CountryName,
                  value: item.IDCountry,
                }))}
                id="IDCountry"
                name="IDCountry"
                optionLabel="name"
                optionValue="value"
                value={formik.values.IDCountry} // Add this line 
                onChange={(e) => {
                  formik.setFieldValue("IDCountry", e.value)
                  fetchCity(e.value)
                }}
                onBlur={formik.handleBlur}
                placeholder={t('Country_placeholder')}
                className="w-full p-inputtext-sm"
              />
            </div> */}


            {/*        <div className="lg:col-6 md:col-12 sm:col-12    ">
              <label htmlFor="AreaActive" className="font-bold block mb-2">{t('label_citiesActive')} </label>
              <Dropdown
                options={statusList}
                id="AreaActive"
                name="AreaActive"
                optionLabel="name"
                optionValue="value"
                value={formik.values.AreaActive} // Add this line 
                onChange={(e) => formik.setFieldValue("AreaActive", e.value)}
                onBlur={formik.handleBlur}
                placeholder={t('status_placeholder')}
                className="w-full p-inputtext-sm"
              />
            </div> */}
          </div>

          <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
            <div className="btn_submit">
              <Button severity="warning" raised icon="pi pi-check" loading={loading} label={t('submit')} size='small' className='mt-3' />
            </div>
            <Link to={'/areas'}>
              <Button severity="warning" label={t('cancel')} type="reset" outlined size='small' className='mt-3' />
            </Link>
          </div>
        </form >
      </div >
    </>
  )
}


export default AddAreas
