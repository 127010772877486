import loginBg from "assets/Images/logo.png";
import logo from "assets/Images/logo.png";
import DefaultImage from "assets/Images/default-image.jpg";

const img = {
    loginBg,
    logo, 
    DefaultImage
}

export default img;
