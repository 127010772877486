// Validate From Fields
import * as Yup from 'yup';
 


export const validateLoginForm = (values) => {
    let validation = Yup.object({
        UserName: Yup.string().required('UserName is required'),
        Password: Yup.string().required('password is required')/* .matches(/^[A-Z][a-z0-9]{0,10}$/,'password must start with uppercase ...') */,
    })
    return validation;
};

export default { 
    validateLoginForm
};