import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';


const Cities = () => {
  let { role } = useContext(FetchApi)
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [Data, setData] = useState(null);

  // Add new cities
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to="/cities/add">
          <Button severity="danger" label={t('add_cities')} icon="pi pi-plus" size='small' />
        </Link>
      </div>
    );
  };

  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2 justify-content-center align-content-center">
        <Tooltip target=".disabled-button" autoHide={false} />
        <Link to={`/cities/edit/${rowData.IDCity}`}>
          <Button icon="pi pi-pencil" rounded outlined text raised className="mr-2" />
        </Link>
      </div>
    );
  };
  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">{t('')} </h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" className='p-inputtext-sm' onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
      </span>
    </div>
  );
  const [page, setPage] = useState(0);
  const [pagesNum, setPagesNum] = useState(null);
  const fetchProfileData = async () => {
    setData([])
    const url = `${process.env.REACT_APP_API_URL}/location/cities`;
    let data = await axios.post(url, { IDPage: page + 1 },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Cities);
    setPagesNum(data?.data?.Response?.Pages)

  }
  useEffect(() => {
    fetchProfileData();
  }, [isLang])

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [editingRow, setEditingRow] = useState(null);

  const statusOptions = [
    { label: t('active'), value: 1 },
    { label: t('inactive'), value: 0 },
  ];

  const onStatusChange = async (rowData, e) => {
    setEditingRow(null);
    console.log(rowData?.IDCity);
    await axios.get(`${process.env.REACT_APP_API_URL}/location/cities/status/${rowData?.IDCity}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchProfileData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };


  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.CityActive === 1 ? t('active') : t('inactive');
    const placeholderText = rowData?.CityActive !== 1 ? t('inactive') : t('active');

    return editingRow === rowData?.IDCity ? (
      <Dropdown  
        value={editingStatus}
        options={statusOptions.filter((option) => option.value !== rowData?.CityActive)}
        onChange={(e) => onStatusChange(rowData, e)}
        placeholder={`${placeholderText}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag
        value={editingStatus}
        severity={getSeverity(rowData.CityActive)}
        onClick={() => setEditingRow(rowData?.IDCity)}
      />
    );
  };
  const getSeverity = (status) => {
    switch (status) {
      case 0:
        return 'danger';

      case 1:
        return 'success';

      case '':
        return 'info';
    }
  };



  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card">

        <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>

        <DataTable className="custom-datatable" scrollable scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          showGridlines
          globalFilter={globalFilter}
          header={header}>
          <Column field="CountryName" header={t('tabel-header-Countries-1')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="CityName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="CityShippingPrice" header={t('CityShippingPrice')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="CityActive" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          {/* <Column    field="UserRank" header={isLang === "en" ? "User Rank" : 'العنوان بالعربي '} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
        </DataTable>

        <Paginator dir='ltr'
          first={page * 10} // Calculate the first record index based on the page
          rows={10}
          totalRecords={pagesNum * 10}
          onPageChange={(e) => setPage(e.page)}
          template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
        />
      </div>

    </div>
  )
}

export default Cities
