import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PreviewsImage from '../Brand/PreviewsImage';
const UpdateProducts = () => {
  let { id } = useParams()
  let { isLang, selectedUpload } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/brands/products/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const [dataGallery, setDataGallery] = useState([]);
  const toast = useRef(null);
  let navigate = useNavigate()

  const items = [
    { label: <Link to={'/products'}>{t('products')}</Link> },
    { label: <Link to={`/products/edit/${id}`} className='p-2 border-round'>{t('products_edit')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/products/edit/page/${id}`, {
      headers: {
        // 'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    })
    if (data?.Success === true) {
      setData(data?.Response);
      setDataGallery(data?.Response?.BrandProductGallery); 
    }
  }
  useEffect(() => {
    fetchData()
  }, []);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageSelect = (event) => setSelectedImage(event.target.files[0]);

  const [selectedIcon, setSelectedIcon] = useState(null);
  const handleIconSelect = (event) => setSelectedIcon(event.target.files[0]);
  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      BrandProductTitleEn: Data?.BrandProductTitleEn || '',
      BrandProductTitleAr: Data?.BrandProductTitleAr || '',
      BrandProductPrice: Data?.BrandProductPrice || '',
      BrandProductOfferPrice: Data?.BrandProductOfferPrice || '',
      BrandProductDescEn: Data?.BrandProductDescEn || '',
      BrandProductDescAr: Data?.BrandProductDescAr || '',
      IDBrandProduct: id || '',
      BrandProductLogo: selectedImage,
      BrandProductGallery: selectedUpload,


    },
    onSubmit: async (values, { resetForm }) => {
      
      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, {
          IDBrandProduct: values?.IDBrandProduct,
          IDSubCategory: values?.IDSubCategory,
          BrandProductTitleEn: values?.BrandProductTitleEn,
          BrandProductTitleAr: values?.BrandProductTitleAr,
          BrandProductDescEn: values?.BrandProductDescEn,
          BrandProductDescAr: values?.BrandProductDescAr,
          BrandProductOfferPrice: values?.BrandProductOfferPrice,
          BrandProductPrice: values?.BrandProductPrice,
          BrandProductLogo: values?.BrandProductLogo,
          BrandProductGallery: selectedUpload,
          BrandProductDealImage: selectedIcon,
        },
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/products')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });
  useEffect(() => {
    formik.setFieldValue('BrandProductLogo', selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    formik.setFieldValue('BrandProductDealImage', selectedIcon);
  }, [selectedIcon]);

  useEffect(() => {
    formik.setValues({
      BrandProductTitleEn: Data?.BrandProductTitleEn || '',
      BrandProductTitleAr: Data?.BrandProductTitleAr || '',
      BrandProductPrice: Data?.BrandProductPrice || '',
      BrandProductOfferPrice: Data?.BrandProductOfferPrice || '',
      BrandProductDescEn: Data?.BrandProductDescEn || '',
      BrandProductDescAr: Data?.BrandProductDescAr || '',
      IDBrandProduct: id || '',
    });
  }, [Data, formik.setValues]);



  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <PreviewsImage gallery={dataGallery} />

      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="grid  mt-5 flex justify-content-center ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col flex justify-content-center align-items-center flex-column ">
            <div className=" shadow-6 mt-3      flex justify-content-center align-items-center   " style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
              <div className="overflow-hidden flex justify-content-center align-items-center"  style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
                {formik.values.BrandProductLogo ? (
                  <Image
                    preview
                    loading="lazy"
                    src={URL.createObjectURL(formik.values.BrandProductLogo)}
                    alt={formik.values.BrandProductLogo.name}
                    width='300px'
                    height='250px'
                    className=' mx-auto '
                    style={{ objectFit: 'contain' }}
                  />
                ) :
                  <>
                    <img
                      loading="lazy"
                      src={Data.BrandProductLogo}
                      alt={'default-image'}
                      className=' rounded-circle mx-auto '
                      style={{ border: '6px solid #fff', width: '300px', maxHeight: '172' }}
                    />
                  </>
                }

              </div>
              <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                <input
                  type="file"
                  id="file-input"
                  accept="image/*"
                  onChange={handleImageSelect}
                  style={{ display: 'none' }}
                />

                <label htmlFor="file-input" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                  <i className='pi pi-image color-wight'></i>
                </label>
              </div>
            </div>
            <label htmlFor="ClientPicture" className="font-bold text-xl block mb-2">{isLang === "en" ? "Main Image" : "الصورة الرئيسية"}</label>

          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col flex justify-content-center align-items-center flex-column ">
            <div className=" shadow-6 mt-3      flex justify-content-center m-auto " style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
              <div className="overflow-hidden flex justify-content-center align-items-center"  style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
                {formik.values.BrandProductDealImage ? (
                  <Image
                    preview
                    loading="lazy"
                    src={URL.createObjectURL(formik.values.BrandProductDealImage)}
                    alt={formik.values.BrandProductDealImage.name}
                    width='300px'
                    height='100%'
                    className=' mx-auto overflow-hidden '
                    style={{ objectFit: 'contain', borderRadius: '10px' }}
                  />
                ) :
                  <>
                    <img
                      loading="lazy"
                      src={Data.BrandProductDealImage}
                      alt={'default-image'}
                      className=' rounded-circle mx-auto '
                      style={{ border: '6px solid #fff', width: '300px', maxHeight: '172' }}
                    />
                  </>
                }

              </div>
              <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                <input
                  type="file"
                  id="file-input-icon"
                  accept="image/*"
                  onChange={handleIconSelect}
                  style={{ display: 'none' }}
                />

                <label htmlFor="file-input-icon" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                  <i className='pi pi-image color-wight'></i>
                </label>
              </div>

            </div>
            <label htmlFor="ClientPicture" className="font-bold text-xl block mb-2">{isLang === "en" ? 'Deal Image' : 'صورة الصفقة'}</label>

          </div>

        </div>



        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandProductTitleEn" className="font-bold block mb-2"> {t('label_categories_nameEn')}    </label>
            <InputText
              name='BrandProductTitleEn'
              id="BrandProductTitleEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandProductTitleEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandProductTitleAr" className="font-bold block mb-2">  {t('label_categories_nameAr')}</label>
            <InputText
              value={formik.values.BrandProductTitleAr}
              name='BrandProductTitleAr'
              id="BrandProductTitleAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandProductOfferPrice" className="font-bold block mb-2">{t('discount')}</label>
            <InputText
              value={formik.values.BrandProductOfferPrice}
              name='BrandProductOfferPrice'
              id="BrandProductOfferPrice" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandProductPrice" className="font-bold block mb-2">{t('price')}</label>
            <InputText
              value={formik.values.BrandProductPrice}
              name='BrandProductPrice'
              id="BrandProductPrice" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandProductDescEn" className="font-bold block mb-2">{t('desc_en')}</label>
            <InputText
              value={formik.values.BrandProductDescEn}
              name='BrandProductDescEn'
              id="BrandProductDescEn" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandProductDescAr" className="font-bold block mb-2">{t('desc_ar')}</label>
            <InputText
              value={formik.values.BrandProductDescAr}
              name='BrandProductDescAr'
              id="BrandProductDescAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>

        </div>



        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="danger" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/products'}>
            <Button label={t('cancel')} severity="danger" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  )
}

export default UpdateProducts
