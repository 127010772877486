import Navber from 'Components/Navber/Navber';
import Error from 'Components/NotFound/Error';
import SidebarMenu from 'Components/Sidebar/SidebarMenu';
import SidebarSM from 'Components/Sidebar/SidebarSM';
import Sidebar from 'Components/Sidebar/Sildebar';
import Auth from 'Layout/Auth';
import BetterWay from 'Layout/BetterWay';
import Brand from 'Page/Admin/Brand/Brand';
import Profile from 'Page/Admin/Profile/Profile';
import AddUsers from 'Page/Admin/Users/AddUsers';
import UpdateUsers from 'Page/Admin/Users/UpdateUsers';
import UserData from 'Page/Admin/Users/UserData';
import AddCategory from 'Page/Admin/setting/Category/AddCategory';
import Category from 'Page/Admin/setting/Category/Category';
import UpdateCategory from 'Page/Admin/setting/Category/UpdateCategory';
import AddSubCategory from 'Page/Admin/setting/SubCategory/AddSubCategory';
import SubCategory from 'Page/Admin/setting/SubCategory/SubCategory';
import UpdateSubCategory from 'Page/Admin/setting/SubCategory/UpdateSubCategory';
import Dashboard from 'Page/Dashbord/Dashbord';
import Login from 'Page/auth/Login/Login';
import AddBrand from './../Page/Admin/Brand/AddBrand';
import UpdateBrand from './../Page/Admin/Brand/UpdateBrand';
import Clients from './../Page/Admin/Clients/Clients';
import ClientsDetails from './../Page/Admin/Clients/ClientsDetails';
import AddProducts from './../Page/Admin/Product/AddProducts';
import Products from './../Page/Admin/Product/Products';
import UpdateProducts from './../Page/Admin/Product/UpdateProducts';
import GeneralSettings from './../Page/Admin/setting/General/GeneralSettings';
import Order from './../Page/Admin/Order/Order';
import OrderDetails from './../Page/Admin/Order/OrderDetails';
import PaymentMethods from './../Page/Admin/setting/PaymentMethods';
import Ads from './../Page/Admin/Ads/Ads';
import AddAds from './../Page/Admin/Ads/AddAds';
import UpdateAds from './../Page/Admin/Ads/UpdateAds';
import AddCities from 'Page/Admin/Locations/Cities/AddCities';
import Cities from 'Page/Admin/Locations/Cities/Cities';
import UpdateCities from 'Page/Admin/Locations/Cities/UpdateCities';
import AddCountries from 'Page/Admin/Locations/Countries/AddCountries';
import Countries from 'Page/Admin/Locations/Countries/Countries';
import UpdateCountries from 'Page/Admin/Locations/Countries/UpdateCountries';
import AddAreas from 'Page/Admin/Locations/Areas/AddAreas';
import Areas from 'Page/Admin/Locations/Areas/Areas';
import UpdateAreas from 'Page/Admin/Locations/Areas/UpdateAreas';

const Component = {
    Auth,
    BetterWay,
    Navber,
    SidebarMenu,
    Sidebar,
    SidebarSM,
    Error,
    Dashboard,
    Auth,
    Login,
    // Users
    PaymentMethods,
    AddUsers,
    UpdateUsers,
    UserData,
    Profile,
    Category,
    AddCategory,
    UpdateCategory,
    SubCategory,
    AddSubCategory,
    UpdateSubCategory,
    GeneralSettings,
    Brand,
    AddBrand,
    UpdateBrand,
    UpdateProducts,
    Products,
    AddProducts,
    Clients,
    ClientsDetails,
    Order,
    OrderDetails,
    Ads,
    AddAds,
    UpdateAds,
    AddCities,
    Cities,
    UpdateCities,
    AddCountries,
    Countries,
    UpdateCountries,
    AddAreas,
    Areas,
    UpdateAreas

}

export default Component




