import Component from 'constants/Component';
import FetchApiContext from 'context/FetchApi';
import LangChange from 'context/LangChange';
import { Tooltip } from 'primereact/tooltip';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './style/App.scss';

function App() {

  function ProtectedRoutes({ children }) {
    let token = localStorage.getItem('tokenBetter')
    if (!token) {
      return <Navigate to="/login" replace={true} />
    } else {
      return children;
    }
  }
  const root = createBrowserRouter([
    {
      path: '', element: <Component.BetterWay />, children: [
        { index: true, element: <ProtectedRoutes><Component.Dashboard /></ProtectedRoutes> },
        { path: '/general_settings', element: <ProtectedRoutes><Component.GeneralSettings /></ProtectedRoutes> },
        { path: '/paymentmethods', element: <ProtectedRoutes><Component.PaymentMethods /></ProtectedRoutes> }, 
        {
          path: '/users', children: [
            { index: true, element: <ProtectedRoutes><Component.UserData /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddUsers /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateUsers /></ProtectedRoutes> }
          ]
        },
        {
          path: '/ads', children: [
            { index: true, element: <ProtectedRoutes><Component.Ads /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddAds /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateAds /></ProtectedRoutes> }
          ]
        }, 
        {
          path: '/categories', children: [
            { index: true, element: <ProtectedRoutes><Component.Category /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddCategory /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateCategory /></ProtectedRoutes> }
          ]
        },  
        {
          path: '/subcategories', children: [
            { index: true, element: <ProtectedRoutes><Component.SubCategory /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddSubCategory /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateSubCategory /></ProtectedRoutes> }
          ]
        },
        {
          path: '/brands', children: [
            { index: true, element: <ProtectedRoutes><Component.Brand /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddBrand /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateBrand /></ProtectedRoutes> },

          ]
        },
        {
          path: '/products', children: [
            { index: true, element: <ProtectedRoutes><Component.Products /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddProducts /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateProducts /></ProtectedRoutes> }
          ]
        },
        {
          path: '/clients', children: [
            { index: true, element: <ProtectedRoutes><Component.Clients /></ProtectedRoutes> },
            { path: 'details/:id', element: <ProtectedRoutes><Component.ClientsDetails /></ProtectedRoutes> }, 
          ]
        }, 
        {
          path: '/orders', children: [
            { index: true, element: <ProtectedRoutes><Component.Order /></ProtectedRoutes> },
            { path: 'details/:id', element: <ProtectedRoutes><Component.OrderDetails /></ProtectedRoutes> }, 
          ]
        }, 
        {
          path: '/countries', children: [
            { index: true, element: <ProtectedRoutes><Component.Countries /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddCountries /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateCountries /></ProtectedRoutes> }
          ]
        },

        {
          path: '/cities', children: [
            { index: true, element: <ProtectedRoutes><Component.Cities /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddCities /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateCities /></ProtectedRoutes> }
          ]
        },

        {
          path: '/areas', children: [
            { index: true, element: <ProtectedRoutes><Component.Areas /></ProtectedRoutes> },
            { path: 'add', element: <ProtectedRoutes><Component.AddAreas /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateAreas /></ProtectedRoutes> }
          ]
        },
        {
          path: '*', element:  <ProtectedRoutes><Component.Error /></ProtectedRoutes> 
        }
      ],
    },
    {
      path: '/login', element: <Component.Auth />, children: [
        {  index: true, element: <Component.Login /> },
      ]
    },
  ])
  const tooltipOptions = {
    position: 'top',
    // يمكنك تحديد لون الخلفية هنا
    style: { backgroundColor: 'blue' },
  };

  return (
    <div className="App">
      <Tooltip target=".disabled-button" autoHide={false} options={tooltipOptions} />

      <FetchApiContext>
        <LangChange>
          <RouterProvider router={root} />
        </LangChange>
      </FetchApiContext>
    </div>
  );
}

export default App;
