import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const BrandRating = ({ modalDone, setModalDone, dataSocialMedia }) => {
    const dt = useRef(null);
    const toast = useRef(null);
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const [Data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);
    const fetchData = async () => {
        setData([])
        const url = `${process.env.REACT_APP_API_URL}/brands/products/ratings`;
        let data = await axios.post(url, { IDBrand: dataSocialMedia?.IDBrand, IDPage: page + 1 },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setPagesNum(data?.data?.Response?.Pages)
        setData(data?.data?.Response?.BrandProductRatings);
    }
    useEffect(() => {
        if (modalDone === true) {
            fetchData();
        }
    }, [isLang, dataSocialMedia?.IDBrand, page])

    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <Dialog
                visible={modalDone}
                style={{ width: '60rem' }}
                modal
                onHide={setModalDone}
                header={isLang === "en" ? "Brand rating" : "تقييم العلامة التجارية"}
                dir={isLang === "en" ? 'ltr' : 'rtl'}
                responsive
            >
                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"  >
                    <Column field="BrandProductRating" header={t('Rating')} style={{ maxWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="BrandProductTitle" header={t('title')} style={{ maxWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="BrandName" header={t('brand_name')} style={{ maxWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="ClientName" header={t('name')} style={{ maxWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="ClientPhone" header={t('phone')} style={{ maxWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="BrandProductReview" header={t('Review')} style={{ maxWidth: '12rem', textAlign: 'center' }}></Column>
                </DataTable>
                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </Dialog>
        </div>
    )
}

export default BrandRating
