
import LogoSvg from "../../constants/LogoSvg";

const useRoutes = () => {
 

    const routes = [
        {
            path: "",
            nameEn: "Dashboard",
            nameAr: "لوحة القيادة",
            icon: <LogoSvg.Dashboard className="logoSvg" style={{ width: 17 }} />,
          

        },
        {
            path: "/orders",
            nameEn: "Orders",
            nameAr: "طلبات",
            icon: <i className="pi pi-shopping-cart"></i>,
        },
        {
            path: "/products",
            nameEn: "Products",
            nameAr: "منتجات",
            icon: <LogoSvg.Prodact className="logoSvg" style={{ width: 17 }} />,
        },
  
        {
            path: "/clients",
            nameEn: "Clients",
            nameAr: "العملاء",
            icon: <LogoSvg.Clients className="logoSvg" style={{ width: 17 }} />,
        },
        {
            path: "/ads",
            nameAr: "لافتات",
            nameEn: "Banners",
            icon: <LogoSvg.Ads className="logoSvg" style={{ width: 20 }} />,

        },
        {
            path: "/categories",
            nameAr: "التصنيفات",
            nameEn: "Categories",
            icon: <LogoSvg.Setting className="logoSvg" style={{ width: 17 }} />,

        },
        {
            path: "/subcategories",
            nameAr: "التصنيفات الفرعية",
            nameEn: "Sub Categories",
            icon: <LogoSvg.Setting className="logoSvg" style={{ width: 17 }} />,

        },
        
        {
            path: "/brands",
            nameEn: "Brands",
            nameAr: "العلامات التجارية",
            icon: <LogoSvg.Brands className="logoSvg" style={{ width: 17 }} />,
        },
        
        {
            nameAr: "المواقع    ",
            nameEn: "Locations ",
            icon: <LogoSvg.Location className="logoSvg" style={{ width: 17 }} />, 

            subRoutes: [
                {
                    path: "/countries",
                    nameAr: "بلدان",
                    nameEn: "Countries",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
 
                },
                {
                    path: "/cities",
                    nameAr: "المحافظات",
                    nameEn: "Governorates",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
 
                },
                {
                    path: "/areas",
                    nameAr: "المناطق",
                    nameEn: "Areas",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
 
                },
            ],
        },

        {
            path: "/users",
            nameEn: "Users",
            nameAr: "مستخدمين",
            icon: <i className='pi pi-user'></i>,
            

        },
    

        {
            path: "/general_settings",
            nameAr: "الاعدادات العامة",
            nameEn: "General settings",
            icon: <i className='pi pi-wrench'></i>,
        },
        // {
        //     path: "/paymentmethods",
        //     nameAr: "طرق الدفع",
        //     nameEn: "Payment methods",
        //     icon: <i className='pi pi-credit-card'></i>,
        // },


    ];
    return {
        routes
    }
}


export default useRoutes;
