
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ToggleButton } from 'primereact/togglebutton';

const Category = () => {

  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [Data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [pagesNum, setPagesNum] = useState(null);
  const topOptions = [
    { label: t('top'), value: 1 },
    { label: t('down'), value: 0 },
  ];

  // Add new categories
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to="/categories/add">
          <Button label={t('categories_add')} severity="danger" icon="pi pi-plus" size='small' />
        </Link>
      </div>
    );
  };
  // logcation path 
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('logPath', currentPath)
  }, [])
  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2 justify-content-center align-content-center">

        <Link to={`/categories/edit/${rowData.IDCategory}`}>
          <Button icon="pi pi-pencil" severity="danger" rounded outlined text raised className="mr-2" />
        </Link>
      </div>
    );
  };
  const filterType = [
    { name: t('reset'), value: 'ALL' },
    { name: t('CLOTHES'), value: 'CLOTHES' },
    { name: t('COSMETICS'), value: 'COSMETICS' },
  ];
  const [selectedTypeFilter, setTypeFilter] = useState(null);
  const [typeFilter, setType] = useState('');

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">

      <div>
        <Dropdown
          value={selectedTypeFilter}
          options={filterType}
          id="CategoryType"
          name="CategoryType"
          optionLabel="name"
          optionValue="value"
          onChange={(e) => {
            setTypeFilter(e.value)
            if (e.value === "ALL") {
              setType('')
              setTypeFilter(null)
            } else {
              setType(e.value)
            }
          }}
          placeholder={isLang === "en" ? 'All' : 'الجميع  '}
          className="p-inputtext-sm w-14rem"
        />
      </div>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
      </span>
    </div>
  );

  const fetchData = useCallback(async () => {

    setData([])
    const url = `${process.env.REACT_APP_API_URL}/categories`;

    let data = await axios.post(url, { IDPage: page + 1, CategoryType: typeFilter },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Categories);
    setPagesNum(data?.data?.Response?.Pages)

  }, [typeFilter]);
  useEffect(() => {
    fetchData();
  }, [isLang, page,typeFilter])



  const [editingRow, setEditingRow] = useState(null);

  const statusOptions = [
    { label: t('active'), value: 1 },
    { label: t('inactive'), value: 0 },
  ];
  const onStatusChange = async (rowData, e) => {
    setEditingRow(null);
    await axios.get(`${process.env.REACT_APP_API_URL}/categories/status/${rowData?.IDCategory}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };
  const topBrandChange = async (rowData, e) => {
    setEditingRowTop(null);
    await axios.get(`${process.env.REACT_APP_API_URL}/categories/top/${rowData?.IDCategory}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };
  const [topBrandRow, setEditingRowTop] = useState(null);
  const statusBodyTemplate1 = (rowData) => {
    const editingStatus = rowData?.TopCategory === 1 ? t('top') : t('down');
    const placeholderText = rowData?.TopCategory !== 1 ? t('down') : t('top');

    return topBrandRow === rowData?.IDCategory ? (
      <Dropdown
        value={editingStatus}
        options={topOptions.filter((option) => option.value !== rowData?.TopCategory)}
        onChange={(e) => topBrandChange(rowData, e)}
        placeholder={`${placeholderText}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag rounded
        value={editingStatus}
        severity={getSeverityTop(rowData.TopCategory)}
        onClick={() => setEditingRowTop(rowData?.IDCategory)}
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.CategoryActive === 1 ? t('active') : t('inactive');
    const placeholderText = rowData?.CategoryActive !== 1 ? t('inactive') : t('active');

    return editingRow === rowData?.IDCategory ? (
      <Dropdown filter
        value={editingStatus}
        options={statusOptions.filter((option) => option.value !== rowData?.CategoryActive)}
        onChange={(e) => onStatusChange(rowData, e)}
        placeholder={`${placeholderText}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag rounded
        value={editingStatus}
        severity={getSeverity(rowData.CategoryActive)}
        onClick={() => setEditingRow(rowData?.IDCategory)}
      />
    );
  };
  // handel image in tabel
  const imageBodyTemplate = (rowData) => {
    return <div className='flex justify-content-center'>
      <Image
        preview
        loading="lazy"
        src={rowData.CategoryLogo}
        alt={'brnad iamge'}
        width='90'
        height='100%'
        className=' mx-auto overflow-hidden shadow-3  border-round' style={{ objectFit: 'cover' }}
      />
    </div>
  };
  // handel image in tabel
  const imageBodyTemplateIcons = (rowData) => {
    return <div className='flex justify-content-center'>
      <Image
        preview
        loading="lazy"
        src={rowData.CategoryIcon}
        alt={'brnad iamge'}
        width='90'
        height='100%'
        className=' mx-auto overflow-hidden shadow-3  border-round' style={{ objectFit: 'cover' }}
      />
    </div>
  };
  // home

  const [checkedItems, setCheckedItems] = useState({});
  const handleToggle = async (rowData) => {
    const updatedCheckedItems = { ...checkedItems, [rowData.id]: !checkedItems[rowData.id] };
    setCheckedItems(updatedCheckedItems);
    await axios.get(`${process.env.REACT_APP_API_URL}/categories/home/${rowData?.IDCategory}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })

  };

  const homeBodyTemplate = (rowData) => (
    <ToggleButton
      size='small'
      className={`w-3rem ${rowData.HomeCategory === 1 ? 'bg-green-500 border-none p-1 text-sm' : 'bg-red-500 border-none p-1 text-0  text-sm'}`}
      checked={rowData.HomeCategory === 1}
      onChange={() => handleToggle(rowData)}
    />
  );

  const getSeverity = (status) => {
    switch (status) {
      case 0:
        return 'danger';

      case 1:
        return 'success';

      case '':
        return 'info';
    }
  };
  const getSeverityTop = (status) => {
    switch (status) {
      case 0:
        return 'danger';

      case 1:
        return 'success';

      case '':
        return 'info';
    }
  };

  const handeText = (text) => {
    return text?.replace(/_/g, ' ').charAt(0).toUpperCase() + text?.replace(/_/g, ' ').slice(1)?.toLowerCase()
  }

  const textType = (text) => {
    if (text?.CategoryType === "CLOTHES") {
      return <span>{t('CLOTHES')}</span>
    } else if (text?.CategoryType === "COSMETICS") {
      return <span>{t('COSMETICS')}</span>
    }
  }


  return (
    <div  >
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card">

        <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>

        <DataTable className="custom-datatable"
          ref={dt}
          value={Data}
          dataKey="id"
          showGridlines
          globalFilter={globalFilter}
          header={header}>
          <Column field="CategoryLogo" header={t('image')} body={imageBodyTemplate}></Column>
          <Column field="CategoryLogo" header={t('icon')} body={imageBodyTemplateIcons}></Column>
          <Column sortable field="CategoryName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column sortable field="CategoryType" header={t('type')} body={textType} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column sortable field="CategoryActive" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column sortable field="HomeCategory" header={t('home')} body={homeBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="TopCategory" header={t('top_Category')} body={statusBodyTemplate1} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
        </DataTable>
        <Paginator dir='ltr'
          first={page * 10} // Calculate the first record index based on the page
          rows={10}
          totalRecords={pagesNum * 10}
          onPageChange={(e) => setPage(e.page)}
          template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
        />
      </div>

    </div>
  )
}

export default Category
