import axios from 'axios';
import { createContext, useEffect, useState } from 'react';

export const FetchApi = createContext([]);

function FetchApiContext({ children }) {
  const [roles, setRoles] = useState([]);

  async function fetchRoles() {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/roles`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setRoles(data?.Response);
  }
  useEffect(() => {
    // Update roles when data changes
    if (localStorage.getItem('tokenBetter')) {
      fetchRoles()
    }
  }, [localStorage.getItem('tokenBetter')]);




  const [categoriesAjex, setCategoriesAjex] = useState([]);
  async function fetchCategoriesAjex(type) {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/categories/ajax`, { CategoryType: type },
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setCategoriesAjex(data?.Response);
  }

  const [subCategoriesAjex, setSubCategoriesAjex] = useState([]);
  async function fetchSubCategoriesAjex(id) {

    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/subcategories/ajax`, { IDCategory: id },
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setSubCategoriesAjex(data?.Response);
  }

  const [brandsAjex, setBrandsAjex] = useState([]);
  async function fetchBrandsAjex(type) {

    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/brands/ajax`, { BrandType: type },
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setBrandsAjex(data?.Response);
  }


  const [productsAjex, setProductsAjex] = useState([]);
  async function fetchProductsAjex(type, id) {

    let { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/brands/products/ajax`, { CategoryType: type, IDBrand: id },
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setProductsAjex(data?.Response);
  }


  const [idBrand, setIdBrand] = useState(localStorage.getItem('IdBrand'));

  useEffect(() => {
    setIdBrand(localStorage.getItem('IdBrand'));
  }, []);

  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);


  async function fetchCountry() {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/countries`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }
    );
    setCountry(data?.Response);
  }

  async function fetchCity(id) {
    if (id) {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/cities/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
          },
        }
      );
      setCity(data?.Response);

    }
  }

  async function fetchArea(id) {
    if (id) {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/areas/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
          },
        }
      );
      setArea(data?.Response);
    }
  }

  return (
    <>
      <FetchApi.Provider
        value={{
          roles,
          idBrand,
          categoriesAjex,
          fetchCategoriesAjex,
          fetchSubCategoriesAjex,
          subCategoriesAjex,
          brandsAjex,
          fetchBrandsAjex,
          productsAjex,
          fetchCountry,
          fetchCity,
          fetchArea,
          country,
          city,
          area,
          fetchProductsAjex
        }}
      >
        {children}
      </FetchApi.Provider>
    </>
  );
}

export default FetchApiContext;
